import React, { useState } from "react";
import { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Typewriter from 'typewriter-effect';

function AppLayout(props) {

    const [mainText, setMainText] = useState("Aditya Mehrotra");
    const [isHovered, setIsHovered] = useState("");
    const [doneTyping, setDoneTyping] = useState(false);


    const updateText = (name = "Aditya Mehrotra") => {
        console.log(name);

        <Typewriter
            onInit={(typewriter) => {
                typewriter.typeString(name)
            }}
        />
        setMainText(name);
    };
    
    useEffect(() => {
        updateText();
    }, []);


    var typewriter = new Typewriter();

    return (
        <div>
            <div>
            <Navbar bg="white" variant="light" style={{margin: 0, alignItems: "center", paddingLeft: "23.5%", paddingRight: "23.5%"}}>
                <Container> 
                    <Navbar.Brand style={{paddingTop: "12%", width: "200%", font: "droid sans", overflow: "hidden", textDecoration: "none", color: "black", display: "inline", fontFamily: "sans-serif", fontSize: "175%" }} to="/">
                        {mainText === "Aditya Mehrotra" ?  
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Aditya Mehrotra").start()
                                }}
                            />
                            : null
                        }
                        {mainText === "Coursework" ? 
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Coursework").start()
                                }}
                            />
                            : null
                        }
                        {mainText === "Projects" ? 
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Projects").start()
                                }}
                            />
                            : null
                        }
                        {mainText === "Experience" ? 
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Experience").start()
                                }}
                            />
                            : null
                        }
                        {mainText === "Papers" ? 
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString("Papers").start()
                                }}
                            />
                            : null
                        }
                    </Navbar.Brand>
                    <Nav style={{paddingTop: "12%", display: "flex", alignItems: "center", textDecoration: "none"}}>
                        <Nav.Link as={Link} onClick={() => updateText("Aditya Mehrotra")} style={{textDecoration: "none", color: ((isHovered === "home") || (mainText === "Aditya Mehrotra")) ? "black" : "#757575", paddingLeft: "10%", paddingRight: "5%", fontFamily: "sans-serif", fontSize: "112.5%"}} onMouseEnter={() => setIsHovered("home")} onMouseLeave={() => setIsHovered("")} to="/">
                            home
                        </Nav.Link>
                        <Nav.Link as={Link} onClick={() => updateText("Coursework")} style={{textDecoration: "none", color: ((isHovered === "coursework") || (mainText === "Coursework")) ? "black" : "#757575", paddingRight: "5%", fontFamily: "sans-serif", fontSize: "112.5%"}} onMouseEnter={() => setIsHovered("coursework")} onMouseLeave={() => setIsHovered("")} to="/coursework">
                            coursework
                        </Nav.Link>
                        <Nav.Link as={Link}  onClick={() => updateText("Projects")} style={{textDecoration: "none", color: ((isHovered === "projects") || (mainText === "Projects")) ? "black" : "#757575", paddingRight: "5%", fontFamily: "sans-serif", fontSize: "112.5%"}} onMouseEnter={() => setIsHovered("projects")} onMouseLeave={() => setIsHovered("")} to="/projects">
                            projects
                        </Nav.Link>
                        <Nav.Link as={Link}  onClick={() => updateText("Experience")} style={{ alignItems: "right" , textDecoration: "none", color: ((isHovered == "experience") || (mainText === "Experience")) ? "black" : "#757575", paddingRight: "5%", fontFamily: "sans-serif", fontSize: "112.5%"}} onMouseEnter={() => setIsHovered("experience")} onMouseLeave={() => setIsHovered("")} to="/experience">
                            experience
                        </Nav.Link>
                        {/*
                        <Nav.Link as={Link}  onClick={() => updateText("Papers")} style={{ alignItems: "right" , textDecoration: "none", color: ((isHovered == "papers") || (mainText === "Papers")) ? "black" : "#757575", paddingRight: "5%", fontFamily: "sans-serif", fontSize: "112.5%"}} onMouseEnter={() => setIsHovered("papers")} onMouseLeave={() => setIsHovered("")} to="/papers">
                            papers
                        </Nav.Link>
                        */}
                    </Nav>
                </Container>
            </Navbar>

            {/* Outlet for rendering child routes */}
            <Outlet />
            </div>
        </div>
    );
}

export default AppLayout;