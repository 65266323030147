import { useCallback, useEffect, useState, Text } from "react"
import ExperienceCard from './PaperCard'


 const Papers = () => {

    const [papersList, setPapersList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setPapersList(prevList => [
            { 
                index: 0,
                title: "Economic ML Paper", 
                description: "I will be engaging in full-stack development, utilizing Angular for the front-end and Java for the back-end, complemented by NoSQL databases to optimize data management. This role will enable me to gain a comprehensive understanding of the entire software development lifecycle, from client-side development to server-side logic and database management.", 
                dates: "May 2024 - Aug 2024",
                company: "Uline",  },
            { 
                index: 1,
                title: "Wearable Technology ML Paper", 
                description: "I create custom websites and applications that help local businesses establish and enhance their online presence. With a focus on full-stack development, I deliver products that meet the needs of my clients.", 
                tags: ["website"], 
                dates: "May 2022 - Present",
                company: "Developer Secure", 
                url: "https://www.developersecure.com" },
            { 
                index: 2,
                title: "Economics Research Paper", 
                description: "I worked with the IT Team to resolve issues across various platforms, leveraging tools such as Microsoft Intune, Active Directory, and software ticketing systems. I developed Python scripts for computer imaging that improved deployment times of new computes.", 
                dates: "May 2023 - Aug 2023",
                company: "UW Credit Union", },
        ]);

    }, []);

    console.log("hi")
    console.log(papersList)
    
    return (
        <div className="App" style={{paddingLeft: "22.5%", paddingRight: "17.5%", paddingTop: "0.25%", font: "droid sans"}}>
            {papersList?.map((experience, index) => (
                <ExperienceCard key={index} experience={experience}>hi</ExperienceCard>
            ))}
        </div>
    );
}

export default Papers;

