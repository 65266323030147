import React from "react"
import { useState, useEffect, useContext} from "react"
import { Card, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'



function ExperienceCard({experience}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isTagHovered, setIsTagHovered] = useState(false);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
          }, 20);
    }, []);


    console.log("props")
    console.log(experience)

    const pressButton = (experience) => {
        console.log("ex");
        console.log(experience.title);
        if (experience.company === 'Uline') {
            navigate('/experience/uline');
        } else if (experience.company === 'UW Credit Union') {
            navigate('/experience/uwcu');
        } else if (experience.company === 'AbbVie') {
            navigate('/experience/abbvie');
        }
    }

    return (
        <Card
            style={{opacity: loaded ? 1 : 0, transition: 'opacity ' + experience.index * 0.75 + 's ease', margin: "2rem", padding: "0.5rem", height: "100%", backgroundColor: isHovered ? "#f0f0f0" : "transparent", font: "droid sans"}} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <Card.Body>
                <a href={experience.url} style={{textDecoration: "none", color: "black"}} target={(experience.url) ? null: "_blank"} onPress={() => pressButton(experience)}>
                    <Link style={{textDecoration: "none", color: "black"}} to={(experience.company === "Uline") ? "/experience/uline" : (experience.company === "UW Credit Union") ? "/experience/uwcu" : (experience.company === "AbbVie") ? "/experience/abbvie" : experience.url} target={(experience.company === "Developer Secure") ? "_blank": null}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                            <div style={{ display: "flex", alignItems: "center"}}>
                                <p style={{ display: "inline", color: "#0058e8", fontWeight: "bold", margin: 0, fontSize: 22}}>#</p>
                                <p style={{ display: "inline", fontWeight: "bold", margin: 0, paddingLeft: "5px", fontSize: 18}}>{experience.title}</p>
                            </div>
                            <p style={{ display: "inline", margin: 0, fontSize: 16, color: "gray"}}>{experience.dates}</p>
                        </div>
                        <p style={{ paddingTop: "0.5%", }}>{experience.company}</p>
                        <p style={{ paddingTop: "1.5%" }}>{experience.description}</p>
                        <div>
                            {experience.tags && experience.tags.map((tag, index) => (
                                <span key={index} style={{ display: "inline-block", color: "red", margin: 0, fontSize: 14, marginRight: "20px" }}>
                                    <a href={(experience.company === "Developer Secure") ? experience.url : "google.com"} target="_blank" style={{display: "inline-block", color: (isTagHovered === false) ? "#0058e8" : "#0445b0", textDecoration: "none"}} onMouseEnter={() => setIsTagHovered(true)} onMouseLeave={() => setIsTagHovered(false)}>   
                                        {tag} 
                                    </a> 
                                <span style={{ margin: "0.4rem" }}></span></span>
                            ))}
                        </div>
                    </Link>
                </a>
            </Card.Body>
        </Card>
    );
}

export default ExperienceCard;