import { useCallback, useEffect, useState } from "react"
import { Card, Button, Image } from "react-bootstrap"

import abbvieLogo from "../../../logo/abbvie_logo.png";

export default function Abbvie(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "25%", paddingRight: "19.5%", paddingTop: "0.5%", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />
            <h1 style={{paddingTop: "0.5%"}}>AbbVie</h1>
            <a href="https://www.abbvie.com/" target="_blank" style={{textDecoration: "none"}}>
              <Image src={abbvieLogo} alt="AbbVie Logo" style={{width: '25%', float: "right", height: '25%', marginTop: "-3.3rem", marginRight: "0.5rem" }}></Image>
            </a>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}>Incoming DevOps Engineer Intern</h4>
            <p style={{ paddingTop: "5%", display: "inline", margin: 0, fontSize: 16, color: "gray"}}>Oct 2024 - Dec 2024</p>
            <p style={{paddingTop: "2.5%"}}>
              I am an incoming Automation DevOps Engineer Intern for Fall 2024.
            </p>
        </div>
    </div>
  );
}

