import { useCallback, useEffect, useState, Text } from "react"
import ExperienceCard from './ExperienceCard'


 const Experience = () => {

    const [experienceList, setExperienceList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setExperienceList(prevList => [
            { 
                index: 0,
                title: "DevOps Engineer Intern", 
                description: "Incoming Automation DevOps Engineer Intern for Fall 2024.", 
                dates: "Oct 2024 - Dec 2024",
                company: "AbbVie", },
            { 
                index: 1,
                title: "Software Developer Intern", 
                description: "I engaged in full-stack development, utilizing Angular for the front-end and Java with Spring Boot for the back-end, while integrating NoSQL databases to enhance data management. Through this role, I gained a comprehensive understanding of the entire software development lifecycle, from designing responsive user interfaces to building robust server-side logic with Spring Boot and ensuring efficient data handling through NoSQL solutions.", 
                dates: "May 2024 - Aug 2024",
                company: "Uline", },
            { 
                index: 2,
                title: "IT Intern", 
                description: "I worked with the IT Team to resolve issues across various platforms, leveraging tools such as Microsoft Intune, Active Directory, and software ticketing systems. I developed Python scripts for computer imaging that improved deployment times of new computes.", 
                dates: "May 2023 - Aug 2023",
                company: "UW Credit Union", },
            { 
                index: 3,
                title: "Founder, Software Developer", 
                description: "I create custom websites and applications that help local businesses establish and enhance their online presence. With a focus on full-stack development, I deliver products that meet the needs of my clients.", 
                tags: ["website"], 
                dates: "Apr 2023 - Present",
                company: "Developer Secure", 
                url: "https://www.developersecure.com" },
        ]);

    }, []);

    console.log("hi")
    console.log(experienceList)
    
    return (
        <div className="App" style={{paddingLeft: "22.5%", paddingRight: "17.5%", paddingTop: "0.25%", font: "droid sans"}}>
            {experienceList?.map((experience, index) => (
                <ExperienceCard key={index} experience={experience}>hi</ExperienceCard>
            ))}
        </div>
    );
}

export default Experience;

